import './styles/App.css';

function App() {
  return (
    <div className="App">
        <div style={{backgroundColor: "hotpink", color: "limegreen", padding: "30px"}}>
          <h1>Welcome to my amazing portfolio page</h1>
        </div>
        <div>
            <h2>My projects</h2>
            <div>
                <div>
                    <a href="https://pgnmusic.com">PGN Music</a>
                    <h4>Created website for PGN Music 2022</h4>
                </div>
                <div>
                    <a href="https://ashes101.com">Ashes101.com</a>
                    <h4>Created website template for Paradox gaming network</h4>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
